

import {
  Component, Watch, Prop, Vue,
} from 'vue-property-decorator'
import { mapState } from 'vuex'
import store from '@/store'
import { l, localeFromPath, truncateText } from '@/utils/helpers'
import arrow from '@/assets/svg/arrow.svg'

@Component({
  components: {
    arrow,
  },
  computed: mapState({
    activeNews: (state: any) => state.active.news,
  }),
  methods: {
    l,
  },
})

export default class News extends Vue {
  constructor() {
    super()
    this.locale = ''
  }

  private locale: string

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.locale = localeFromPath(this.$route.path || '/')
    // Fetch News content in the current language from the API
    store.dispatch(
      'fetch',
      `/?contentType=news&&locale=${this.locale}`,
    )
      .then((result: any) => {
        if (result) {
          // Set the fetched news content as active news in the VUEX store
          try {
            store.dispatch('set', { key: 'active.news', data: result.slice(0, 3) })
          } catch (err) {
            // console.log(err)
          }
        }
      })
      .catch(
        (error: string) => { throw new Error(error) },
      )
  }

  truncateDescription(text:string) {
    return truncateText(text, 100)
  }
}
