

import path from 'path'
import {
  Component, Watch, Vue,
} from 'vue-property-decorator'
import { mapState } from 'vuex'
import _ from 'lodash'

import store from '@/store'
import { l, localeFromPath } from '@/utils/helpers'
import Loader from '@/components/atoms/Loader.vue'
import Hero from '@/components/molecules/Hero.vue'
import ContentSection from '@/components/organisms/ContentSection.vue'
import FourOhFour from '@/components/molecules/FourOhFour.vue'
import Feature from '@/components/molecules/Feature.vue'
import CuratedContentSection from '@/components/organisms/CuratedContentSection.vue'
import News from '@/components/molecules/News.vue'

import conf from '../../configuration'

@Component({
  components: {
    Feature,
    Hero,
    News,
    CuratedContentSection,
    FourOhFour,
    ContentSection,
    Loader,
  },
  // This is used by the Vue Meta package
  // to add dynamic meta information to HTML head
  // See also public/index.html
  metaInfo() {
    const {
      title, description, image, locale, imageAlt,
    } = this.$store.getters.activePost
    return {
      title,
      meta: [
        { name: 'description', content: description },

        // Open graph
        { property: 'og:title', content: title || conf.rssTitle },
        { property: 'og:description', content: description || conf.rssDescription },
        { property: 'og:url', content: window.location.href },
        {
          property: 'og:image',
          content: image ? `${conf.media.baseUrl}${conf.media.sourcePath}${conf.media.namespace}${_.startsWith(image, '/') ? image : `/${image}`}` : conf.rssFeedImage,
        },
        {
          property: 'og:image:alt',
          content: imageAlt || 'earth',
        },

        // Twitter
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:summary', content: description },
        { property: 'twitter:title', content: title || conf.rssTitle },
        { property: 'twitter:description', content: description || conf.rssDescription },
        { property: 'twitter:url', content: window.location.href },
        {
          property: 'twitter:image',
          content: image ? `${conf.media.baseUrl}${conf.media.sourcePath}${conf.media.namespace}${_.startsWith(image, '/') ? image : `/${image}`}` : conf.rssFeedImage,
        },
        {
          property: 'twitter:image:alt',
          content: imageAlt || 'earth',
        },
      ],
      htmlAttrs: {
        lang: locale,
      },
      // Remove the /en/ part from the canonical URL if the language is English
      // Make sure canonical URLs always end with a /
      link: [
        {
          rel: 'canonical',
          href: locale === 'en' && !this.$route.path.includes('/en/')
            ? `${conf.url}${_.replace(_.endsWith(this.$route.path, '/') ? this.$route.path : `${this.$route.path}/`, '/', '/en/')}`
            : `${conf.url}${_.endsWith(this.$route.path, '/') ? this.$route.path : `${this.$route.path}/`}`,
        },
      ],
    }
  },
  computed: mapState({
    contentNotFound: (state: any) => (
      state.status === 404
    ),
    landingPage: (state: any) => (state.active ? state.active.post.post : undefined),
    curatedContentSections: (state: any) =>
      // eslint-disable-next-line implicit-arrow-linebreak
      ((state.active && state.active.post && state.active.post.post)
        ? state.active.post.post.curated
        : undefined
      ),
    activeContent: (state: any) => (
      (state.active
      && state.active.theme
      && state.active.theme.content
      && state.active.theme.content.active
      && !_.isEmpty(state.active.theme.content.active)
      )
        ? state.active.theme.content.active
        : state.active.theme.content.passive // Return all content when filter result set is empty
    ),
  }),
  methods: {
    l,
  },
})
export default class LandingPage extends Vue {
  constructor() {
    super()
    this.locale = ''
  }

  private locale: string

  resetTagsTopicsAndContent() {
    try {
      store.dispatch('set', { key: 'active.theme.content.active', data: [] })
      store.dispatch('set', { key: 'active.theme.content.passive', data: [] })
      store.dispatch('set', { key: 'active.theme.topics.active', data: [] })
      store.dispatch('set', { key: 'active.theme.topics.passive', data: [] })
      store.dispatch('set', { key: 'active.theme.tags.active', data: [] })
      store.dispatch('set', { key: 'active.theme.tags.passive', data: [] })
    } catch (err) {
      document.dispatchEvent(new Event('custom-render-trigger'))
    }
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.resetTagsTopicsAndContent()
    // Rest active post on URL change
    try {
      store.dispatch('set', { key: 'active.post.post', data: {} })
    } catch (err) {
      document.dispatchEvent(new Event('custom-render-trigger'))
    }
    this.locale = localeFromPath(this.$route.path || '/')
    if (this.$route && this.$route.path) {
      let query = ''
      const slug = path.basename(this.$route.path)
      // if '/' or 'en'
      if (this.$route.path === '/' || slug === this.locale) {
        query = `/?translationKey=776b14db-4613-40d3-8012-52e045b5ed2a&&locale=${this.locale}`
      } else {
        query = `/?slug=${slug}&&locale=${this.locale}`
      }
      // Fetch landingPage content in the current language from the API
      try {
        store.dispatch(
          'fetch',
          query,
        )
          .then((result: any) => {
            if (result[0]) {
              // Reset Feature in store
              // Set the fetched landingPage content as active post in the VUEX store
              try {
                store.dispatch('set', { key: 'active.post.post', data: result[0] })

                /* Tell the prerender-spa-plugin that data fetching is complete
                * and that prerendering can start, see vue.config.js.
                * Wait until all the stores variables are set and give
                * the render engine some time to render.
                */
                if (this.$store.state
                    && this.$store.state.active
                    && this.$store.state.active.feature
                    && this.$store.state.active.curated
                    && this.$store.state.active.news
                    && this.$store.state.active.post
                    && this.$store.state.active.post.post) {
                  setTimeout(() => document.dispatchEvent(new Event('custom-render-trigger')), 10000)
                }
              } catch (err) {
                document.dispatchEvent(new Event('custom-render-trigger'))
              }
              try {
                store.dispatch('set', { key: 'active.feature', data: {} })
              } catch (err) {
                document.dispatchEvent(new Event('custom-render-trigger'))
              }
              // Reset Curated Content in store
              try {
                store.dispatch('set', { key: 'active.curated', data: {} })
              } catch (err) {
                document.dispatchEvent(new Event('custom-render-trigger'))
              }

              const contentType = l(`${slug}Slug`, this.locale)
              let contentQuery = ''
              if (contentType.length > 0) {
                // Naive attempt to get the singualar f.e. database instead of databases
                contentQuery = `/?contentType=${_.trimEnd(contentType, 's')}&&locale=${this.locale}`
              }
              // Fetch the content from the API
              store.dispatch(
                'fetch',
                contentQuery,
              )
                .then((contentForPage: any) => {
                  if (contentForPage && contentForPage[0]) {
                    try {
                      store.dispatch('set', { key: 'active.theme.content.passive', data: contentForPage })
                    } catch (error) {
                      document.dispatchEvent(new Event('custom-render-trigger'))
                    }
                  }
                })
                // Fetch all Topics from the API
                .then(() => {
                  try {
                    store.dispatch(
                      'fetch',
                      `/?contentType=topic&&locale=${this.locale}`,
                    )
                      .then((topics: any) => {
                        if (topics && !_.isEmpty(topics)) {
                          try {
                            // Set all Topics in the VUEX store
                            store.dispatch('set', { key: 'content.topics', data: topics })
                            const { topicsFromThemeContent } = this.$store.getters
                            if (
                              _.isEmpty(topicsFromThemeContent)
                              || _.isUndefined(topicsFromThemeContent)
                            ) {
                              store.dispatch('set', { key: 'active.theme.topics.passive', data: [] })
                            } else {
                              try {
                                // Set Topics for this post in VUEX store
                                store.dispatch(
                                  'set', { key: 'active.theme.topics.passive', data: topicsFromThemeContent },
                                )
                              } catch (error) {
                                document.dispatchEvent(new Event('custom-render-trigger'))
                              }
                            }
                          } catch (error) {
                            try {
                              store.dispatch('set', { key: 'active.theme.topics.passive', data: [] })
                            } catch (err) {
                              document.dispatchEvent(new Event('custom-render-trigger'))
                            }
                            setTimeout(() => document.dispatchEvent(new Event('custom-render-trigger')), 3000)
                          }
                        }
                      })
                  } catch (err) {
                    document.dispatchEvent(new Event('custom-render-trigger'))
                  }
                })
                // Fetch Tags
                .then(() => {
                  try {
                    store.dispatch(
                      'fetch',
                      `/?contentType=tag&&locale=${this.locale}`,
                    )
                      .then((tags: any) => {
                        if (slug === 'workshops') {
                          try {
                            store.dispatch(
                              'fetch',
                              `/?contentType=workshopTopic&&locale=${this.locale}`,
                            ).then((res) => {
                              store.dispatch('set', { key: 'active.theme.tags.passive', data: res })
                            })
                          } catch (err) {
                            document.dispatchEvent(new Event('custom-render-trigger'))
                          }
                        }
                        if (tags && !_.isEmpty(tags)) {
                          try {
                            // Set all Tags in the VUEX store
                            store.dispatch('set', { key: 'content.tags', data: tags })
                            const { tagsFromThemeContent } = this.$store.getters
                            if (
                              _.isEmpty(tagsFromThemeContent)
                              || _.isUndefined(tagsFromThemeContent)
                            ) {
                              store.dispatch('set', { key: 'active.theme.tags.passive', data: [] })
                            } else {
                              try {
                                // Set Tags for this post in the VUEX store
                                store.dispatch(
                                  'set', { key: 'active.theme.tags.passive', data: tagsFromThemeContent },
                                )
                              } catch (error) {
                                document.dispatchEvent(new Event('custom-render-trigger'))
                              }
                            }
                          } catch (error) {
                            try {
                              store.dispatch('set', { key: 'active.theme.tags.passive', data: [] })
                            } catch (err) {
                              document.dispatchEvent(new Event('custom-render-trigger'))
                            }
                            setTimeout(() => document.dispatchEvent(new Event('custom-render-trigger')), 3000)
                          }
                        }
                      })
                  } catch (err) {
                    document.dispatchEvent(new Event('custom-render-trigger'))
                  }
                })
                .catch(
                  (error: string) => {
                    this.resetTagsTopicsAndContent()
                  },
                )
            }
          })
          .catch(
            (error: string) => {
              try {
                store.dispatch('set', { key: 'status', data: 404 })
              } catch (err) {
                document.dispatchEvent(new Event('custom-render-trigger'))
              }
              setTimeout(() => document.dispatchEvent(new Event('custom-render-trigger')), 3000)
            },
          )
      } catch {
        document.dispatchEvent(new Event('custom-render-trigger'))
      }
    }
  }
}
