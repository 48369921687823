

import {
  Component, Watch, Prop, Vue,
} from 'vue-property-decorator'
import { mapState } from 'vuex'
import _ from 'lodash'
import store from '@/store'
import ResponsiveImage from '@/components/atoms/ResponsiveImage.vue'

@Component({
  components: {
    ResponsiveImage,
  },
  computed: mapState({
    activeFeature: (state: any) => state.active.feature,
  }),
})
export default class Feature extends Vue {
  @Prop({ required: true }) private uuid!: string

  @Prop({ default: false }) private isFullWidth!: boolean

  @Watch('uuid')
  onUuidChange(newUuid: string, oldUuid: string) {
    this.fetchFeature()
  }

  @Watch('$route', { immediate: true, deep: true })
  onUrlChange() {
    this.fetchFeature()
  }

  fetchFeature() {
    // Reset Feature in store
    try {
      store.dispatch('set', { key: 'active.feature', data: {} })
    } catch (err) {
      document.dispatchEvent(new Event('custom-render-trigger'))
    }
    store.dispatch(
      'fetch',
      `/?uuid=${this.uuid}`,
    )
      .then((result: any) => {
        if (result) {
          try {
            store.dispatch('set', { key: 'active.feature', data: result })
          } catch (err) {
            // console.log(err)
          }
        } else {
          try {
            store.dispatch('set', { key: 'active.feature', data: {} })
          } catch (err) {
            // console.log(err)
          }
        }
      })
      .catch(
        (error: string) => { throw new Error(error) },
      )
  }
}
